import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';   // Import Swiper React components
import 'swiper/css';                // Import Swiper CSS
import 'swiper/css/autoplay';        // Import Autoplay CSS
import { Autoplay } from 'swiper/modules';  // Correct import for Autoplay module in Swiper 8+

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function PenggunaLayanan() {
    useEffect(() => {
        // Inisialisasi animasi jika diperlukan
    }, []);

    // Daftar ikon
    const icons = [
        { id: 1, src: '/assets-web/logo/telkom.png', alt: 'Telkom' },
        { id: 2, src: '/assets-web/logo/xl_axiata.png', alt: 'XL Axiata' },
        { id: 3, src: '/assets-web/logo/partner_with_us.png', alt: 'Your Logo Here' },
        { id: 4, src: '/assets-web/logo/icon+.png', alt: 'Icon +' },
        { id: 5, src: '/assets-web/logo/Indosat_Ooredoo_Hutchison.png', alt: 'Indosat Ooredoo Hutchison' },
        { id: 6, src: '/assets-web/logo/infotek.png', alt: 'Infotek' },
        { id: 7, src: '/assets-web/logo/lintasarta.png', alt: 'Lintasarta' },
        { id: 8, src: '/assets-web/logo/partner_with_us.png', alt: 'Your Logo Here' },
        { id: 9, src: '/assets-web/logo/sckom.png', alt: 'SCKOM' },
        { id: 10, src: '/assets-web/logo/supercorridor.png', alt: 'Trans Indonesia Superkoridor ' },
        { id: 11, src: '/assets-web/logo/bku.png', alt: 'BKU' },
        { id: 12, src: '/assets-web/logo/crossnet.png', alt: 'Crossnet' },
        { id: 13, src: '/assets-web/logo/partner_with_us.png', alt: 'Your Logo Here' },
        { id: 14, src: '/assets-web/logo/icon+.png', alt: 'Icon +' },
        { id: 15, src: '/assets-web/logo/Indosat_Ooredoo_Hutchison.png', alt: 'Indosat Ooredoo Hutchison' },
    ];

    return (
        <div className="relative py-32 bg-gray-900 text-white">
            <div className="container mx-auto px-4 lg:px-8">
                <h1 className="text-3xl font-bold text-blue-400 text-center mb-8">Pengguna Layanan</h1>

                {/* Swiper Carousel */}
                <Swiper
                    spaceBetween={30}          // Jarak antar slide
                    slidesPerView={5}          // Berapa banyak slide yang ditampilkan
                    loop={true}                // Infinite loop
                    autoplay={{
                        delay: 0,              // Tidak ada jeda
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,  // Pause ketika mouse berada di atas slider
                    }}
                    speed={3000}               // Kecepatan transisi
                    modules={[Autoplay]}       // Daftarkan modul Autoplay di sini
                >
                    {icons.map((icon) => (
                        <SwiperSlide key={icon.id}>
                            <LazyLoadImage
                                src={icon.src}
                                alt={icon.alt}
                                className="w-32 h-auto object-contain mx-auto"
                                effect="blur"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Contact Section */}
                <div className="text-center p-10">
                    <p className="text-xl font-semibold">Apakah Anda ingin logo perusahaan Anda tampil di sini?</p>
                    <p className='text-md'>Hubungi kami di{" "} </p>
                    <a href="mailto:marketing@len-telko.co.id" className="text-yellow-500 hover:underline">
                        marketing@len-telko.co.id
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PenggunaLayanan;
