import React, { useEffect } from 'react';  // Pastikan useEffect di-import

import PageHeader from '../PageHeader';
import VisiMisi from './VisiMisi';
import ProfilPerusahaan from './ProfilPerusahaan';
import SejarahPerusahaan from './SejarahPerusahaan';
import StrukturOrganisasi from './StrukturOrganisasi';

function TentangKamiLayout() {
    useEffect(() => {
        const handleAnchorClick = (e) => {
            e.preventDefault();

            const headerHeight = document.querySelector('.page-header')?.offsetHeight || 0; // Sesuaikan dengan kelas header Anda
            const targetElement = document.querySelector(e.currentTarget.getAttribute('href'));

            if (targetElement) {
                const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - headerHeight - 80;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        };

        // Tambahkan event listener untuk setiap anchor link
        const anchors = document.querySelectorAll('a[href^="#"]');
        anchors.forEach(anchor => anchor.addEventListener('click', handleAnchorClick));

        return () => {
            // Hapus event listener ketika komponen tidak lagi digunakan
            anchors.forEach(anchor => anchor.removeEventListener('click', handleAnchorClick));
        };
    }, []);
    return (
        <>
            <PageHeader title="Tentang Kami" />
            <div className="pt-20 px-10">
                {/* Sidebar navigasi di sebelah kiri */}
                <nav className="p-6 fixed left-0 top-50 w-1/4 hidden lg:block">
                    <ul className="space-y-4">
                        <li>
                            <a
                                href="#profil-perusahaan"
                                className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition"
                            >
                                Profil Perusahaan
                            </a>
                        </li>
                        <li>
                            <a
                                href="#visi-misi"
                                className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition"
                            >
                                Visi & Misi
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sejarah"
                                className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition"
                            >
                                Sejarah Perusahaan
                            </a>
                        </li>
                        <li>
                            <a
                                href="#struktur-organisasi"
                                className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition"
                            >
                                Struktur Organisasi
                            </a>
                        </li>
                    </ul>
                </nav>
                <main className="w-full md:w-full lg:w-3/4 lg:ml-[25%]">
                    {/* Profil Perusahaan */}
                    <section id="profil-perusahaan">
                        <ProfilPerusahaan />
                    </section>

                    {/* Visi Misi */}
                    <section id="visi-misi">
                        <VisiMisi />
                    </section>

                    {/* Sejarah Perusahaan */}
                    <section id="sejarah">
                        <SejarahPerusahaan />
                    </section>

                    {/* Struktur Organisasi */}
                    <section id="struktur-organisasi">
                        <StrukturOrganisasi />
                    </section>
                </main>

            </div >
        </>
    );
}

export default TentangKamiLayout;
