import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MapboxMap from './MapboxMap';  // Jika MapboxMap ada di folder yang sama

import AOS from 'aos';
import 'aos/dist/aos.css';



function KontakKami() {
    const [kantorData, setKantorData] = useState([]);

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    useEffect(() => {
        axios.get('https://strapis.len-telko.co.id/api/kantors')
            .then(response => {
                // Periksa bentuk data yang diterima
                console.log(response.data);

                // Jika data ada di dalam response.data.data, ambil array-nya
                if (Array.isArray(response.data.data)) {
                    setKantorData(response.data.data);  // Pastikan untuk mengambil array yang benar
                } else {
                    console.error('Data tidak ditemukan atau bukan array');
                }
            })
            .catch(error => {
                console.error('Error fetching data from Strapi: ', error);
            });
    }, []);

    return (
        <>
            <div className="container mx-auto px-6 py-20 mt-10" id="id-kontak-kami">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                    {/* Sebelah Kiri: Detail 3 Kantor */}
                    <div className="space-y-8">
                        <h2 className="text-2xl font-bold text-blue-900">Kantor Kami</h2>

                        {Array.isArray(kantorData) && kantorData.map((kantor, index) => (
                            <div key={index} className="bg-white rounded-lg" data-aos="fade-right">
                                <h3 className="text-xl font-semibold">{kantor.attributes.nama_kantor}</h3>
                                <p className="mt-2 text-gray-700">{kantor.attributes.alamat}</p>
                                <p className="mt-2 text-gray-700">{kantor.attributes.kota}</p>
                                <p className="mt-2 text-gray-700">{kantor.attributes.kode_pos}&emsp;
                                    <a
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        href={kantor.attributes.gmaps}
                                        className="text-xs bg-gradient-to-r from-green-600 to-green-900 hover:from-green-500 hover:to-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                                    >
                                        OPEN In Google Maps
                                    </a>
                                </p>
                            </div>
                        ))}

                    </div>

                    {/* Mapbox akan berada di sini */}
                    {/* Sebelah Kanan: Mapbox */}
                    <div className="relative" data-aos="fade-down">
                        <h2 className="text-2xl font-bold text-blue-900 mb-4">Peta Lokasi</h2>
                        <MapboxMap kantorData={kantorData} />  {/* Mengirim data kantor ke MapboxMap */}
                    </div>
                </div>

                {/* Form Kontak */}
                <div className="mt-12">
                    {/* Form Kontak */}
                    {/* Form Kontak */}
                    <form className="space-y-6">

                        {/* Alamat Email */}
                        <div data-aos="fade-right">
                            <label className="block text-sm font-medium text-gray-700">Alamat Email</label>
                            <input
                                type="email"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring focus:ring-indigo-200"
                                placeholder="example@domain.com"
                                required
                            />
                        </div>

                        {/* Nama Lengkap */}
                        <div data-aos="fade-right">
                            <label className="block text-sm font-medium text-gray-700">Nama Lengkap</label>
                            <input
                                type="text"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring focus:ring-indigo-200"
                                placeholder="Nama Lengkap"
                                required
                            />
                        </div>

                        {/* Nomor HP */}
                        <div data-aos="fade-right">
                            <label className="block text-sm font-medium text-gray-700">Nomor HP</label>
                            <input
                                type="tel"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring focus:ring-indigo-200"
                                placeholder="+62 812 3456 7890"
                                required
                            />
                        </div>

                        {/* Tujuan */}
                        <div data-aos="fade-right">
                            <label className="block text-sm font-medium text-gray-700">Tujuan</label>
                            <select
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring focus:ring-indigo-200"
                                required
                            >
                                <option>Pilih Tujuan</option>
                                <option>Informasi Perusahaan</option>
                                <option>Kerjasama</option>
                                <option>Produk/Layanan</option>
                                <option>Lainnya</option>
                            </select>
                        </div>

                        {/* Isi Pesan */}
                        <div data-aos="fade-right">
                            <label className="block text-sm font-medium text-gray-700">Pesan Anda</label>
                            <textarea
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring focus:ring-indigo-200"
                                rows="4"
                                placeholder="Tulis pesan Anda di sini..."
                                required
                            ></textarea>
                        </div>

                        {/* Tombol Submit */}
                        <div data-aos="fade-right">
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-gradient-to-r from-red-600 to-red-900 hover:from-red-500 hover:to-red-700 text-white font-bold rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Kirim Pesan
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default KontakKami;
