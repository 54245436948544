import React, { useEffect } from 'react';  // Pastikan useEffect di-import

import PageHeader from '../PageHeader';
import KepemilikanSaham from './KepemilikanSaham';
import DewanKomisaris from './DewanKomisaris';
import Direksi from './Direksi';

function ManajemenLayout() {
    useEffect(() => {
        const headerHeight = document.querySelector('.page-header')?.offsetHeight || 0; // Adjust according to your header's class name

        // Smooth scroll with offset for anchor links
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const targetElement = document.querySelector(this.getAttribute('href'));
                if (targetElement) {
                    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - headerHeight - 80;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            });
        });
    }, []);
    return (
        <>
            <PageHeader title="Manajemen" />
            <div className="flex pt-20 px-10">
                {/* Sidebar navigasi di sebelah kiri */}
                <nav className="p-6 fixed left-0 top-50 w-1/4 hidden lg:block">
                    <ul className="space-y-4">
                        <li>
                            <a href="#kepemilikan-saham" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Kepemilikan Saham
                            </a>
                        </li>
                        <li>
                            <a href="#dewan-komisaris" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Dewan Komisaris
                            </a>
                        </li>
                        <li>
                            <a href="#direksi" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Direksi
                            </a>
                        </li>
                    </ul>
                </nav>

                {/* Konten di sebelah kanan */}
                <main className="w-full md:w-full lg:w-3/4 lg:ml-[25%]">
                    {/* Kepemilikan Saham */}
                    <section id="kepemilikan-saham">
                        <KepemilikanSaham />
                    </section>

                    {/* Dewan Komisaris */}
                    <section id="dewan-komisaris">
                        <DewanKomisaris />
                    </section>

                    {/* Direksi */}
                    <section id="direksi">
                        <Direksi />
                    </section>
                </main>
            </div>
        </>
    );
}

export default ManajemenLayout;
