import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';

// Import komponen lainnya
// import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import AboutMe from './components/AboutMe';
import MediaCard from './components/Media/MediaCard';
import BannerFooter from './components/BannerFooter';
// import DekomDireksi from './components/DekomDireksi';
import PalapaRingTengah from './components/PalapaRingTengah';
import PenggunaLayanan from './components/PenggunaLayanan';
import KontakKami from './components/KontakKami/KontakKami';
// import SkemaBisnis from './components/SkemaBisnis';
import MediaLayout from './components/Media/MediaLayout';
import MediaSection from './components/Media/MediaSection';
import MediaDetail from './components/Media/MediaDetail';


import TentangKamiLayout from './components/TentangKami/TentangKamiLayout';
import ManajemenLayout from './components/Manajemen/ManajemenLayout';
import PalapaRingLayout from './components/PalapaRing/PalapaRingLayout';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  const turnstileRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const storedVerification = localStorage.getItem('isVerified');
    const verificationTimestamp = localStorage.getItem('verificationTime');

    const now = new Date().getTime();

    if (storedVerification === 'true' && verificationTimestamp) {
      const elapsedTime = now - parseInt(verificationTimestamp, 10);

      // Check if 1 hour has passed since verification
      if (elapsedTime < 60 * 60 * 1000) { // 1 hour in milliseconds
        setIsVerified(true);
      } else {
        // Reset verification if time exceeds 1 hour
        localStorage.removeItem('isVerified');
        localStorage.removeItem('verificationTime');
        setIsVerified(false);
      }
    } else {

      // Interval untuk memastikan `window.turnstile` tersedia
      const interval = setInterval(() => {
        if (window.turnstile && turnstileRef.current) {
          window.turnstile.render(turnstileRef.current, {
            sitekey: "0x4AAAAAAAsfHjcLuIrJmLy3", // Replace with your Site Key
            callback: (newToken) => {
              setIsVerified(true);
              const timestamp = new Date().getTime();
              localStorage.setItem('isVerified', 'true'); // Simpan status verifikasi
              localStorage.setItem('verificationTime', timestamp.toString()); // Simpan waktu verifikasi
            },
            'error-callback': () => {
              console.error("Turnstile error: Failed to verify user.");
              setIsVerified(false); // Reset status jika terjadi error
            },
            'expired-callback': () => {
              console.warn("Turnstile expired: Please try again.");
              setIsVerified(false); // Reset status jika token kadaluarsa
            },
          });
          clearInterval(interval); // Hentikan pengecekan setelah berhasil
        }
      }, 100);

      return () => clearInterval(interval); // Hentikan interval saat komponen di-unmount
    }
  }, []);

  return (
    <Router>
      <TrackPageView />
      <div>
        {!isVerified ? (
          // Render Turnstile jika belum terverifikasi
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              color: 'white',
              zIndex: 9999,
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <p className='py-10'>Please verify that you are human to access the website</p>
              <div
                ref={turnstileRef}
                className="cf-turnstile"
                style={{
                  width: '100%',
                  maxWidth: '400px', // Mengatur lebar maksimum
                  margin: '0 auto',
                }}
              ></div>
            </div>
          </div>
        ) : (
          // Render konten utama jika sudah terverifikasi
          <>
            {/* Navbar selalu tampil */}
            <Navbar />

            {/* Routes untuk mengelola konten utama */}
            <Routes>
              {/* Halaman Utama */}
              <Route path="/" element={
                <>
                  {/* Preloader hanya muncul saat halaman pertama kali dimuat */}
                  {/* <Preloader /> */}
                  <Carousel />
                  <AboutMe />
                  <MediaCard />
                  <PalapaRingTengah />
                  <PenggunaLayanan />
                  <BannerFooter />
                </>
              } />

              <Route path="/tentang-kami" element={<TentangKamiLayout />} />
              <Route path="/manajemen" element={<ManajemenLayout />} />
              <Route path="/palapa-ring" element={<PalapaRingLayout />} />

              {/* Parent route untuk media */}
              <Route path="/media/:jenis" element={<MediaLayout />}>
                {/* Child route untuk daftar artikel berdasarkan jenis */}
                <Route index element={<MediaSection />} />
                {/* Child route untuk detail artikel berdasarkan jenis dan id */}
                <Route path=":id" element={<MediaDetail />} />
              </Route>

              <Route path="/kontak-kami" element={<KontakKami />} />
            </Routes>

            {/* Footer selalu tampil */}
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
