import React, { useEffect } from 'react';  // Pastikan useEffect di-import

import PageHeader from '../PageHeader';

import ProyekStrategisNasional from './ProyekStrategisNasional';
import PalapaRingTengahDetail from './PalapaRingTengahDetail';
import LokasiDanWilayah from './LokasiDanWilayah';

function PalapaRingLayout() {
    useEffect(() => {
        const headerHeight = document.querySelector('.page-header')?.offsetHeight || 0; // Adjust according to your header's class name

        // Smooth scroll with offset for anchor links
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const targetElement = document.querySelector(this.getAttribute('href'));
                if (targetElement) {
                    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - headerHeight - 80;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            });
        });
    }, []);
    return (
        <>
            <PageHeader title="Palapa Ring" />
            <div className="flex border pt-20">
                {/* Sidebar navigasi di sebelah kiri */}
                <nav className="p-6 fixed left-0 top-50 w-1/4 hidden lg:block">
                    <ul className="space-y-6">
                        <li>
                            <a href="#proyek-strategis-nasional" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Proyek Strategis Nasional
                            </a>
                        </li>
                        <li>
                            <a href="#palapa-ring-tengah" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Palapa Ring Tengah
                            </a>
                        </li>
                        <li>
                            <a href="#lokasi-dan-wilayah" className="text-lg text-black hover:text-blue-500 hover:bg-gray-200 p-3 block rounded-md transition">
                                Lokasi dan Wilayah
                            </a>
                        </li>
                    </ul>
                </nav>

                {/* Konten di sebelah kanan */}
                <main className="w-full md:w-full lg:w-3/4 lg:ml-[25%]">
                    {/* Proyek Strategis Nasional */}
                    <section id="proyek-strategis-nasional">
                        <ProyekStrategisNasional />
                    </section>

                    {/* Palapa Ring Tengah */}
                    <section id="palapa-ring-tengah">
                        <PalapaRingTengahDetail />
                    </section>

                    {/* Lokasi dan Wilayah */}
                    <section id="lokasi-dan-wilayah">
                        <LokasiDanWilayah />
                    </section>
                </main>
            </div>
        </>
    );
}

export default PalapaRingLayout;
