import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function BannerFooter() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // Durasi animasi
        });
    }, []);

    return (
        <>
            <div className="bg-gray-100" id="banner-footer">

                {/* Image */}
                <div data-aos="zoom-in">
                    <img
                        alt="banner footer"
                        src="/assets-web/banner-footer.png"
                        className="w-full"
                    />
                </div>
            </div>
        </>
    );
}

export default BannerFooter;
