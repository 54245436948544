import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function DewanKomisaris() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const komisarisData = [
        {
            nama: 'Teni Agustini',
            jabatan: 'Komisaris Utama',
            lokasi: 'Jakarta',
            deskripsi: 'Merupakan Warga Negara Indonesia dan saat ini berdomisili di Jakarta. Beliau merupakan lulusan Magister Manajemen angkatan 1994. <br><br>Beliau memiliki lebih dari 30 tahun pengalaman di industri telekomunikasi, termasuk 15 tahun di posisi eksekutif. Sebagai salah satu anggota tim pendiri Indihome, jaringan serat optik pertama di Indonesia oleh PT Telekomunikasi Indonesia, beliau berkontribusi signifikan dalam transformasi layanan telekomunikasi nasional. Saat ini menjabat sebagai Direktur Utama PT TRG Investama, setelah sebelumnya menjabat sebagai Direktur Utama PT Akses Prima Indonesia, serta mengemban peran strategis sebagai Komisaris di PT Telkom Akses, PT Patrakom, Telkom Vision, dan PT PSN.',
            gambar: 'assets-web/photo/teni_agustini_sqr.png',
        },
        {
            nama: 'Prasetyo Suharto',
            jabatan: 'Komisaris',
            lokasi: 'Bekasi',
            deskripsi: 'Merupakan Warga Negara Indonesia dan saat ini berdomisili di Bekasi. Beliau merupakan lulusan Sarjana Hukum angkatan 1985. Beliau memiliki pengalaman kerja lebih dari 30 tahun dan telah menjadi ahli di bidang Sumber Daya Manusia & Umum serta Operasional.',
            gambar: 'assets-web/photo/prasetyo_suharto_sqr.png',
        },
    ];

    return (
        <div className="container mx-auto mt-20 px-4 pt-16">
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-down">
                    Dewan Komisaris
                </h1>
            </div>

            {komisarisData.map((komisaris, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg p-8 mb-10"
                    data-aos="fade-up"
                >
                    <img
                        src={komisaris.gambar}
                        alt={komisaris.nama}
                        className="w-40 h-40 md:w-52 md:h-52 object-cover rounded-lg mb-6 md:mb-0 md:mr-8"
                        effect="blur"
                    />
                    <div className="flex flex-col text-center md:text-left">
                        <h2 className="text-2xl font-semibold">{komisaris.nama}</h2>
                        <p className="text-lg text-red-700 mb-3">{komisaris.jabatan}</p>
                        <hr className="mb-3" />
                        <p
                            className="text-md text-gray-600 text-justify"
                            dangerouslySetInnerHTML={{ __html: komisaris.deskripsi }}
                        ></p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DewanKomisaris;
