import { Outlet } from 'react-router-dom';

function MediaLayout() {
    return (
        <div className="media-layout">
            {/* Ini akan menjadi tempat untuk menampilkan halaman child (jenis atau detail) */}
            <Outlet />
        </div>
    );
}

export default MediaLayout;
