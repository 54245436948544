import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faGlobe, faNetworkWired, faProjectDiagram, faBullseye } from '@fortawesome/free-solid-svg-icons';

const VisiMisi = () => {
    return (
        <section className="pt-16">
            <div className="container mx-auto px-4">
                {/* Judul Visi & Misi */}
                <div className="text-center mb-12" data-aos="fade-down">
                    <h2 className="text-3xl font-extrabold tracking-wide mb-4 text-[#005da2]">Visi dan Misi</h2>
                </div>

                {/* Konten Visi & Misi */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    {/* Visi */}
                    <div data-aos="fade-right">
                        <div className="flex items-center justify-center mb-4">
                            <FontAwesomeIcon icon={faLightbulb} className="h-10 w-10 text-[#e6212a] mr-4" />
                            <h3 className="text-2xl font-bold text-center text-[#005da2]">Visi</h3>
                        </div>
                        <p className="text-lg leading-relaxed text-justify">
                            Menjadi Penyelenggara Telekomunikasi Nasional Berbasis Backbone.
                        </p>
                    </div>

                    {/* Misi */}
                    <div data-aos="flip-right">
                        <div className="flex items-center justify-center mb-4">
                            <FontAwesomeIcon icon={faBullseye} className="h-10 w-10 text-[#e6212a] mr-4" />
                            <h3 className="text-2xl font-bold text-center text-[#005da2]">Misi</h3>
                        </div>
                        <ul className="space-y-4 text-lg leading-relaxed text-justify">
                            <li className="flex items-start">
                                <FontAwesomeIcon icon={faGlobe} className="h-8 w-8 text-[#e6212a] mr-4 mt-1" />
                                Menyediakan layanan konektivitas yang berkualitas.
                            </li>
                            <li className="flex items-start">
                                <FontAwesomeIcon icon={faNetworkWired} className="h-8 w-8 text-[#e6212a] mr-4 mt-1" />
                                Menjadi model pengelolaan operator jaringan.
                            </li>
                            <li className="flex items-start">
                                <FontAwesomeIcon icon={faProjectDiagram} className="h-8 w-8 text-[#e6212a] mr-4 mt-1" />
                                Pemanfaatan jaringan telekomunikasi secara nasional dalam rangka percepatan ekonomi berbasis digital.
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Timeline Section */}
                <div className="text-center pt-12 pb-8" data-aos="fade-down">
                    <h2 className="text-3xl font-extrabold tracking-wide mb-4 text-[#005da2]">Nilai Perusahaan</h2>
                </div>
                <div className="flex flex-col justify-around" data-aos="fade-down">
                    <div className="flex flex-col gap-4 w-full max-w-4xl mx-auto">
                        {[
                            { title: 'A', heading: 'AMANAH', text: 'Memegang teguh kepercayaan yang diberikan', bg: 'bg-[#8fcae6]' },
                            { title: 'K', heading: 'KOMPETEN', text: 'Terus belajar dan mengembangkan kapabilitas', bg: 'bg-[#229fbd]' },
                            { title: 'H', heading: 'HARMONIS', text: 'Saling peduli dan menghargai perbedaan', bg: 'bg-[#013047]' },
                            { title: 'L', heading: 'LOYAL', text: 'Berdedikasi dan mengutamakan kepentingan bangsa dan negara', bg: 'bg-[#ffb704]' },
                            { title: 'A', heading: 'ADAPTIF', text: 'Terus berinovasi dan antusias dalam menggerakan ataupun menghadapi perubahan', bg: 'bg-[#fb8500]' },
                            { title: 'K', heading: 'KOLABORATIF', text: 'Membangun Kerjasama yang sinergis', bg: 'bg-[#c45d10]' }
                        ].map((item, index) => (
                            <div key={index} className={`flex ${item.bg} p-6 rounded-lg`}>
                                <div className="flex-none w-wull flex items-center justify-center text-4xl font-bold text-white">
                                    {item.title}
                                </div>
                                <div className="flex-grow pl-4 text-center">
                                    <h3 className="text-2xl font-bold text-white mb-1">{item.heading}</h3>
                                    <p className="text-white text-xl">{item.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </section>
    );
};

export default VisiMisi;
