import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Direksi() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const direksiData = [
        {
            nama: 'Ganda Yudha Asparaini',
            jabatan: 'Plt. Direktur Utama',
            lokasi: 'Bandung',
            deskripsi: 'Merupakan Warga Negara Indonesia dan saat ini berdomisili di Bandung. Beliau merupakan lulusan sarjana Teknik Elektro di Universitas Brawijaya pada tahun 1995 dan meraih Gelar Magister Administrasi bisnis di ITB pada tahun 2005.<br><br>Beliau memiliki pengalaman lebih dari 25 tahun di BUMN, mencakup berbagai posisi strategis, seperti Direktur Pemasaran PT Surya Energi Indotama, Direktur Utama PT Len Agra Energi, Komisaris Utama Len Bisa Fintek, General Manager Unit Bisnis Energi PT Len Industri (Persero), Senior General Manager Portofolio PT Len Industri (Persero), serta Direktur Keuangan dan SDM PT Len Telekomunikasi Indonesia.',
            gambar: 'assets-web/photo/ganda_yudha_asparaini_sqr.png',
        },
        {
            nama: 'Dirgantara Putra',
            jabatan: 'Direktur Bisnis dan Operasi',
            lokasi: 'Jakarta',
            deskripsi: 'Merupakan Warga Negara Indonesia dan saat ini berdomisili di Jakarta. Memperoleh Gelar sarjana Teknik Elektro di Institut Teknologi Bandung pada tahun 1993 dan meraih Gelar Magister Managemen di Universitas Indonesia pada tahun 2002.<br><br>Beliau memiliki pengalaman profesional yang luas di berbagai perusahaan terkemuka, termasuk menjabat sebagai Direktur Utama PT Akses Prima Indonesia, VP Business Development PT Teknologi Riset Global Investama, dan Head of Operation PT Parama Adi Mitra. Sebelumnya, beliau berkarir sebagai Head of Sales & Marketing di PT Samsung Telecommunication Indonesia serta memegang peran Customer Account Team (CAT) dan Customer Support Manager di PT Alcatel Indonesia.',
            gambar: 'assets-web/photo/dirgantara_putra_sqr.png',
        },
        {
            nama: 'Priono Joni Hartanto',
            jabatan: 'Plt. Direktur Keuangan dan SDM',
            lokasi: 'Bandung',
            deskripsi: 'Merupakan Warga Negara Indonesia dan saat ini berdomisili di Bandung. Memperoleh Gelar Sarjana Teknik Elektro di Universitas Brawijaya pada tahun 1995. <br><br>Beliau memiliki pengalaman 20 tahun berkarir di PT Len Industri (Persero), termasuk menjabat sebagai Supply Chain Management Senior General Manager, Senior GM Logistik, dan Vice President Divisi Logistik. Sebelumnya, beliau juga berperan sebagai Manager Operasi Logistik dan Manager Komersial Unit Bisnis Elektronika Pertahanan (UB. ELHAN).',
            gambar: 'assets-web/photo/priono_joni_hartono_sqr.png',
        },
    ];

    return (
        <div className="container mx-auto pt-16 px-4">
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-up">
                    Direksi
                </h1>
            </div>

            {direksiData.map((direksi, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow-lg p-8 mb-10"
                    data-aos="fade-up"
                >
                    <img
                        src={direksi.gambar}
                        alt={direksi.nama}
                        className="w-40 h-40 md:w-52 md:h-52 object-cover rounded-lg mb-6 md:mb-0 md:mr-8"
                        effect="blur"
                    />
                    <div className="flex flex-col text-center md:text-left">
                        <h2 className="text-2xl font-semibold">{direksi.nama}</h2>
                        <p className="text-lg text-red-700 mb-3">{direksi.jabatan}</p>
                        <hr className="mb-3" />
                        <p
                            className="text-md text-gray-600 text-justify"
                            dangerouslySetInnerHTML={{ __html: direksi.deskripsi }}
                        ></p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Direksi;
