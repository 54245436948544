import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PageHeader from '../PageHeader';

function MediaDetail() {
    const { id } = useParams();  // Hanya mengambil 'id', 'jenis' tidak diperlukan
    const [article, setArticle] = useState(null);
    const [slides, setSlides] = useState([]);  // State untuk menyimpan data slider

    useEffect(() => {
        // Fetch article data by ID and populate slider data from Strapi
        fetch(`https://strapis.len-telko.co.id/api/articles?populate=*&filters[slug][$eq]=${id}`)
            .then(response => response.json())
            .then(data => {
                if (data.data.length > 0) {
                    const fetchedArticle = data.data[0]; // Mengambil artikel pertama dari array
                    setArticle(fetchedArticle);  // Set state article
                    // Assuming you store slider images in a field called `Image` in Strapi
                    const sliderImages = fetchedArticle.attributes.Image?.data || [];
                    const sliderData = sliderImages.map((img, index) => ({
                        id: index + 1,
                        imgSrc: `https://strapis.len-telko.co.id${img.attributes.url}`,
                        title: img.attributes.caption || 'Slide ' + (index + 1),  // Optional title or fallback
                        description: img.attributes.description || 'No description available'  // Optional description
                    }));

                    setSlides(sliderData);  // Set state for slides
                } else {
                    console.log('Artikel tidak ditemukan');
                }
            })
            .catch(error => console.error('Error fetching article:', error));
    }, [id]);

    if (!article) {
        return <div>Loading...</div>;  // Menampilkan loading jika data belum siap
    }

    const { Title, Image, DataContent } = article.attributes;

    // Fungsi untuk merender blok Rich Text dengan handling bold, italic, dll.
    const renderContent = (DataContent) => {
        return DataContent.map((block, index) => {
            if (block.type === 'paragraph') {
                return (
                    <p key={index} className="text-justify text-gray-700 leading-loose text-lg mb-4">
                        {block.children.map((child, childIndex) => {
                            if (child.type === 'text') {
                                let textElement = child.text;

                                // Apply bold if the text is bold
                                if (child.bold) {
                                    textElement = <strong key={childIndex}>{textElement}</strong>;
                                }

                                // Apply italic if the text is italic
                                if (child.italic) {
                                    textElement = <em key={childIndex}>{textElement}</em>;
                                }

                                return textElement;  // Return text node
                            }
                            return null;  // Handle other types if necessary
                        })}
                    </p>
                );
            }
            return null; // If there are other block types, handle them here
        });
    };

    return (
        <>
            <PageHeader title="Media" />
            <div className="container mx-auto px-4 pt-20">
                {/* Title */}
                <h1 className="text-2xl leading-loose text-center font-extrabold text-black my-6">{Title}</h1> {/* Perbesar ukuran judul */}

                {/* Thumbnail */}
                {Image?.data?.[0]?.attributes?.url ? (
                    <LazyLoadImage
                        src={`https://strapis.len-telko.co.id${Image.data[0].attributes.url}`}
                        alt={Title}
                        className="w-full object-cover mb-8"
                    />
                ) : null}

                {/* Render the DataContent Rich Text */}
                <div className="text-2x text-gray-700 text-justify leading-relaxed"> {/* Perbesar teks konten */}
                    {DataContent ? renderContent(DataContent) : "No content available."}
                </div>

                {/* Published Date and Author */}
                <div className="mt-8 mb-32">
                    {/* Slider Section */}
                    {slides.length > 0 && (
                        <div className="relative w-full mx-auto mt-[80px] flex flex-row justify-center items-center space-x-4">
                            {slides.map((slide) => (
                                <div key={slide.id} className="relative w-1/3">
                                    <LazyLoadImage
                                        src={slide.imgSrc}
                                        alt={slide.title}
                                        className="w-full h-auto object-cover"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MediaDetail;
