import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Tambahkan CSS Partikel
const particleStyles = `
  .particle {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: 0.5;
    animation: move 7s linear infinite;
  }

  @keyframes move {
    0% {
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) translateX(0vw);
      opacity: 0;
    }
  }
`;

function AboutMe() {
    useEffect(() => {
        AOS.init({
            duration: 2000, // Durasi animasi
        });
    }, []);

    return (
        <>
            <div className="relative py-16 bg-gray-900 text-white">
                {/* Partikel untuk latar belakang */}
                <style>{particleStyles}</style>
                <div className="particle w-2 h-2 top-0 left-0"></div>
                <div className="particle w-3 h-3 top-20 left-20"></div>
                <div className="particle w-1 h-1 top-40 left-10"></div>
                <div className="particle w-2 h-2 top-60 left-30"></div>
                <div className="particle w-2 h-2 top-80 left-40"></div>

                {/* Parallax Background */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `url('https://picsum.photos/1080/720')`,
                        backgroundAttachment: 'fixed', // Parallax effect
                        zIndex: -1
                    }}
                ></div>

                {/* Content Section */}
                <div className="container mx-auto px-4 lg:px-8">
                    <div className="flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-12">
                        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-12">
                            {/* Video Section */}
                            <div
                                className="lg:w-1/2 w-full"
                            >
                                <div className="relative">
                                    <video
                                        controls
                                        poster="/assets-web/thumbnail.png"
                                        preload="none"
                                        className="w-full rounded-lg shadow-lg"
                                    >
                                        <source
                                            src="/assets-web/company-profile-video.mp4"
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>

                            {/* Text Section */}
                            <div
                                className="lg:w-1/2 w-full text-left"
                                data-aos="fade-left"
                                data-aos-delay="200"
                            >
                                <h1 className="text-3xl font-bold mb-4 text-blue-400">PROFIL</h1>
                                <p className="text-xl mb-8 text-gray-200 text-justify leading-relaxed">
                                    PT Len Telekomunikasi Indonesia (PT LTI) merupakan Badan Usaha
                                    Pelaksana (BUP) yang ditugaskan untuk melaksanakan proyek Pembangunan
                                    dan Pengelolaan Jaringan Tulang Punggung Serat Optik Palapa Ring Paket
                                    Tengah. PT LTI mempunyai peran strategis karena PT LTI turut serta
                                    dalam menjaga kedaulatan negara dan membangun kesejahteraan
                                    masyarakat.
                                </p>
                                <Link
                                    to="tentang-kami#profil-perusahaan"
                                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out"
                                >
                                    SELENGKAPNYA...
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutMe;



