import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Tambahkan CSS Partikel
const particleStyles = `
  .particle {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: 0.5;
    animation: move 7s linear infinite;
  }

  @keyframes move {
    0% {
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) translateX(0vw);
      opacity: 0;
    }
  }
`;

function PalapaRingTengah() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Durasi animasi
        });
    }, []);

    return (
        <>
            <div className="relative py-16 bg-gray-900 text-white">
                {/* Partikel untuk latar belakang */}
                <style>{particleStyles}</style>
                <div className="particle w-2 h-2 top-0 left-0"></div>
                <div className="particle w-3 h-3 top-20 left-20"></div>
                <div className="particle w-1 h-1 top-40 left-10"></div>
                <div className="particle w-2 h-2 top-60 left-30"></div>
                <div className="particle w-2 h-2 top-80 left-40"></div>

                {/* Title Section */}
                <div className="text-center mb-12" id="palapa">
                    <h1
                        className="text-3xl font-bold text-blue-400"
                        data-aos="fade-down"
                    >
                        PALAPA RING TENGAH
                    </h1>
                </div>

                {/* Content Section */}
                <div className="container mx-auto px-4 lg:px-8 text-justify">
                    <div className="flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-12">
                        {/* Text Section */}
                        <div className="lg:w-1/2 w-full" data-aos="fade-right">
                            <p className="text-xl leading-relaxed text-gray-300 mb-4">
                                Sistem komunikasi di era digital ini, menuntut adanya efisiensi dalam pengiriman informasi dari narasumber kepada penerima. Efisiensi itu berupa kecepatan waktu dalam pengiriman pesan dengan serat optik, narasumber dapat mengirimkan informasi dengan kapasitas yang besar baik itu besaran data maupun kecepatan.
                            </p>
                            <p className="text-xl leading-relaxed text-gray-300 mb-4">
                                Dengan perubahan ini, keterbukaan informasi dapat memberikan dampak yang baik bagi bangsa dan negara. Melalui sistem komunikasi yang baik, pemerintah dan rakyatnya akan terhubung satu dengan yang lainnya.
                            </p>
                            <p className="text-xl leading-relaxed text-gray-300">
                                Terinspirasi oleh sejarah bangsa, pemerintah Indonesia menggunakan "Palapa Ring" sebagai nama proyek pembangunan infrastruktur jaringan tulang punggung bagi telekomunikasi nasional.
                            </p>
                        </div>

                        {/* Image Section */}
                        <div
                            className="lg:w-1/2 w-full"
                            data-aos="fade-right"
                        >
                            {/* Wrapper dengan background putih dan padding */}
                            <div className="bg-white p-4 rounded-lg shadow-lg">
                                <LazyLoadImage
                                    alt="Palapa Ring Tengah"
                                    src="/assets-web/profil_palapa_ring.webp"
                                    className="w-full rounded-lg"
                                    effect="blur"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-10'>
                        <Link
                            to="palapa-ring#proyek-strategis-nasional"
                            className="bg-red-600 hover:bg-red-700 text-white  font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out"
                        >
                            SELENGKAPNYA...
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PalapaRingTengah;
