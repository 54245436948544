import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function PalapaRingTengahDetail() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // Durasi animasi
        });
    }, []);
    return (
        <>
            <div className="pt-16 px-8">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-[#005da2]" data-aos="fade-down">
                        Palapa Ring Tengah
                    </h1>
                </div>

                {/* Deskripsi Umum Palapa Ring */}
                <div className="container mx-auto px-4 text-justify">
                    <div>
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            <strong>Palapa Ring</strong> merupakan proyek infrastruktur telekomunikasi yang bertujuan membangun jaringan serat optik di seluruh wilayah Indonesia, termasuk daerah-daerah terpencil. Proyek ini dikenal sebagai "tulang punggung/backbone" internet Indonesia, menghubungkan wilayah-wilayah yang sebelumnya kurang memiliki akses telekomunikasi dan digital. Palapa Ring terdiri dari tiga paket: Paket Barat, Paket Tengah, dan Paket Timur.
                        </p>
                        <p className="text-md leading-7 mb-4" data-aos="fade-right">
                            Salah satu bagian dari proyek ini adalah **Palapa Ring Paket Tengah**, yang bertujuan untuk meningkatkan konektivitas di wilayah Kalimantan, Sulawesi, dan Kepulauan Maluku Utara.
                        </p>

                        {/* Gambar Palapa Ring Tengah */}
                        <div className="mt-8" data-aos="fade-down">
                            <LazyLoadImage
                                src="/assets-web/profil_palapa_ring.webp"
                                alt="Palapa Ring Tengah"
                                className="w-full rounded-lg bg-white shadow-lg"
                                effect="blur"
                            />
                        </div>

                        {/* Informasi Detail Palapa Ring Tengah */}
                        <div className="mt-8 pt-6 rounded">
                            <h2 className="text-2xl font-bold mb-4" data-aos="fade-right">Tentang Palapa Ring Tengah</h2>
                            <p className="text-md leading-7 mb-4" data-aos="fade-right">
                                Palapa Ring Tengah merupakan bagian dari Proyek KPBU (Kerjasama Pemerintah dengan Badan Usaha) dengan model kerjasama **Bangun Milik Guna Serah (Build Own Operate Transfer/BOOT)**. Proyek ini mencakup pembangunan jaringan serat optik di wilayah Kalimantan, Sulawesi, dan Kepulauan Maluku Utara, dengan cakupan 17 kabupaten di wilayah tersebut.
                            </p>
                            <p className="text-md leading-7 mb-4" data-aos="fade-right">
                                Proyek ini menggunakan teknologi DWDM terkini dan mampu menyediakan kapasitas hingga 100 Gbps pada setiap proyeknya. Palapa Ring Tengah telah beroperasi sejak 21 Desember 2018 dan menyediakan akses telekomunikasi di 17 kota layanan dan 10 kota interkoneksi.
                            </p>
                            <p className="text-md leading-7 mb-4" data-aos="fade-right">
                                Total panjang jaringan serat optik yang dibangun adalah:
                            </p>
                            <ul className="list-disc list-inside text-md leading-7 mb-4" data-aos="fade-right">
                                <li>Kabel Darat: 1.320,15 km</li>
                                <li>Kabel Laut: 1.823,51 km</li>
                                <li>Total Panjang: 3.143,66 km</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PalapaRingTengahDetail;
