import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';   // Import Swiper React components
import 'swiper/css';                // Import Swiper CSS
import 'swiper/css/autoplay';        // Import Autoplay CSS
import { Autoplay } from 'swiper/modules';  // Correct import for Autoplay module in Swiper 8+

function Carousel() {
    const slides = [
        {
            id: 1,
            imgSrc: "/assets-web/hero_banner_1.png",
        },
        {
            id: 2,
            imgSrc: "/assets-web/hero_banner_2.png",
        },
        {
            id: 3,
            imgSrc: "/assets-web/hero_banner_3.png",
        }
    ];

    return (
        <>
            <div className="relative w-full mx-auto mt-[80px]" id='id-carousel'>
                <Swiper
                    slidesPerView={1}          // Berapa banyak slide yang ditampilkan
                    loop={true}                // Infinite loop
                    autoplay={{
                        delay: 5000,              // Tidak ada jeda
                        disableOnInteraction: true,
                        pauseOnMouseEnter: false,  // Pause ketika mouse berada di atas slider
                    }}
                    speed={3000}               // Kecepatan transisi
                    modules={[Autoplay]}       // Daftarkan modul Autoplay di sini
                >
                    {slides.map((slide) => (
                        <SwiperSlide key={slide.id}>
                            <div className="relative w-full h-full">
                                <img
                                    src={slide.imgSrc}
                                    alt={slide.title}
                                    className="w-full"
                                    effect="blur"
                                />
                                <div className="absolute inset-0 bg-opacity flex flex-col justify-center items-center text-white p-4">
                                    <p className="text-2xl mb-2">{slide.title}</p>
                                    <p className="text-lg font-medium w-3/5 whitespace-normal hover:whitespace-pre">{slide.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}

export default Carousel;
