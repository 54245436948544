import React from 'react';

function Footer() {
    return (
        <>
            <footer className="bg-gradient-to-b from-[#111827] to-black text-white pt-20 pb-10 z-100">
                <div className="container mx-auto px-4 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">

                        {/* Address Section */}
                        <div>
                            <h5 className="text-lg font-bold mb-4 border-b-2 border-white inline-block">
                                Our Address
                            </h5>
                            <p className="mt-2">PT Len Telekomunikasi Indonesia</p>
                            <p className="mt-1 flex items-center justify-center md:justify-start">
                                <i className="fas fa-map-marker-alt mr-2"></i> Menara MTH Lt.M, Suite M-02, Jl. Letjen M.T. Haryono Kav.23, Jakarta 12820 - Indonesia
                            </p>
                        </div>

                        {/* Contact Section */}
                        <div>
                            <h5 className="text-lg font-bold mb-4 border-b-2 border-white inline-block">
                                Contact Us
                            </h5>
                            <p className="mt-2 flex items-center justify-center md:justify-start">
                                <i className="fas fa-phone mr-2"></i> +62 21 2283 3872 / +62 81 1953 0600
                            </p>
                            <p className="mt-1 flex items-center justify-center md:justify-start">
                                <i className="fas fa-envelope mr-2"></i> kontak@len-telko.co.id
                            </p>
                        </div>

                        {/* Social Media Section */}
                        <div className='text-center'>
                            <h5 className="text-lg font-bold mb-4 border-b-2 border-white inline-block">
                                Social Media
                            </h5>
                            <div className="space-x-4">
                                <a href="https://www.facebook.com/len.telko/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f text-3xl hover:text-gray-300 transition"></i>
                                </a>
                                <a href="https://www.instagram.com/len.telko/?hl=en" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram text-3xl hover:text-gray-300 transition"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/len-telko/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in text-3xl hover:text-gray-300 transition"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Certification Section */}
                    <div className="mt-8 flex justify-center items-center space-x-8">
                        <div className="text-center text-sm">
                            <p>Certified: ISO 9001:2015, ISO 14001:2015, ISO 45001:2018</p>
                        </div>
                    </div>

                    {/* Copyright Section */}
                    <div className="border-t border-white mt-8 pt-4 text-center text-sm">
                        <span>Copyright &copy; 2024 PT Len Telekomunikasi Indonesia. <br />All Rights Reserved.</span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
